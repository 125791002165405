import api from "@/api";
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";
import tableActions from "@/store/templates/table/actions";

const fetchCall = api.annotationType.fetchAll;

export default {
  ...tableActions,
  async [DISPATCH_FETCH](context) {
    return await tableActions.fetch(context, { fetchCall });
  }
};
